import React from 'react';
import Modal from '../../../components/Modal';
import Quote from '../../../components/Quote';

const media = {
  secTitle: 'Conversations about Racism Rarely Lead to Action',
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/S_RoTpwDZwA',
  },
};

export default () => (
  <Modal closeTo="Learned" media={media}>
    <p className="Title--2 Title--c">
      Honest and fact-based conversations about the depth of the challenges
      presented by racism and classism in mixed racial company seldom take place
      and rarely lead to action.
    </p>
    <p>
      Having honest conversations about race and class was overwhelmingly cited
      as a solution to many of the issues communities face. Yet these
      conversations about race and class rarely take place. According to a
      survey conducted by the Pew Research Center, when Americans do discuss
      race and race relations, these conversations are occurring within family
      and friend networks. Most black and Asian adults (63 percent and 66
      percent, respectively) say race or race relations come up in their
      conversations with family and friends at least sometimes, compared to
      about half of white and Latino adults. But black adults are more likely
      than other racial or ethnic groups to say these topics come up often; 27
      percent of black adults say this compared with just 11 percent of white,
      15 percent of Latino, and 13 percent of Asian adults.
    </p>
    <p>
      Most participants we spoke with were aware that these conversations
      necessitate personal discomfort, vulnerability and deep empathy. A number
      of white participants stated that white people that have never been
      exposed to conversations about race have the most difficulty engaging in
      dialogue. Many withdraw from conversations around the topic and feel
      alienated by unfamiliar language or terms used to discuss issues of race
      and inequality (e.g., “white privilege”). When candid conversations do
      occur, they rarely turn into action.
    </p>
    <Quote
      quote="It makes me uncomfortable talking about it, because you have to walk on eggshells and watch what you say. And because people...you might not mean something offensively, but someone’s going to take it that way...A lot of people are trying to forget it."
      person="White female"
      title="Richmond"
    />
    <Quote
      quote="Most of the core of this work is building empathy. And so, I think you build empathy on a couple of different levels. And I think it starts first with a being actually in a relationship or being with someone else that is of another to you, relative to you, and actually sitting down and talking to them and learning more about them and they learn about you."
      person=""
      title="Bentonville"
    />
    <Quote
      quote="I think that white people think the discussions about race are a trap. I think that white people think that they can never win, and I think that white people are very invested in winning."
      person="White trans man, 37"
      title="Louisville"
    />
    <Quote
      quote="When we sit around that table, we talk and find out, we learn each other too. You learn that this person is just like you."
      person="Black female, 69"
      title="Drew"
    />
    <Quote
      quote="And so, what I have found is, and this is just my little bitty experience, is any kind of conversations around race have to be in tandem, or alongside of other conversations, right, done in subtle ways, because folks get again so entrenched and get offended by those conversations. Because, nobody wants to be labeled a racist. Right?"
      person="Black male, 49"
      title="Lake Providence"
    />
  </Modal>
);
